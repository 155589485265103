*,
::before,
::after {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}
#root {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  height: 40px;
  border-radius: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 700px;
  height: 1000px;
  box-shadow: 0 5px 12px rgb(0 0 0 / 0.5);
  overflow: hidden;
}

.header {
  border-bottom: solid black 1px;
  padding-left: 10px;
}

.side {
  display: flex;
  flex-direction: column;
  height: 950px;
  width: 200px;
  border-right: solid black 1px;
  padding-left: 10px;
}

.contact-info > * {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  height: 100%;
  flex: 0 1 auto;
  width: 200px;
  gap: 10px;
  word-wrap: break-word;
}

.skills > * {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  height: 100%;
  width: 200px;
  gap: 10px;
  word-wrap: break-word;
}

button {
  width: 70px;
  border-radius: 5px;
}

.main > * {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  height: auto;
  justify-content: flex-start;
}

.content {
  display: flex;
}

.education,
.experience {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  height: 100%;
  width: 200px;
  gap: 10px;
  word-wrap: break-word;
}
